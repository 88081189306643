import React from 'react'
import './Safety.scss'
import Cards from '../../components/cards/Cards'

function Safety() {
  return (
    <div className='safe'>
      <div className='block__banner--safety'>
        <div className='block__shadow'></div>
        <h1 className='safe__headline'>Safety!</h1>
      </div>
      <div className='wrapper'>
        <div className='safety'>
          <div className='safety__sub'>
            <h2 className='safe__para'>Our emphasis on safety technology, driver training, and compliance with regulations demonstrates a strong commitment to safety within our operations. Continuous monitoring and improvement of these safety measures will help maintain high standards of safety for our drivers and the communities they serve.</h2>
            <p className='block__text block__text--mod'><b>Fleet Safety Technologies</b>: Utilizing advanced safety technologies such as FCAM, AI VEDR, Lane Departure Warning Systems, and GPS fleet analytics is crucial for ensuring the safety of both drivers and the public. Continuously updating these technologies to the latest standards ensures our fleet remains equipped with the best safety features available.</p>
            <p className='block__text block__text--mod'><b>Regular Inspections and Maintenance</b>: Conducting weekly inspections and maintenance before every dispatch assignment is a must for our operations. It ensures that each vehicle is in optimal condition and compliant with DOT regulations, reducing the risk of accidents due to mechanical failures.</p>
            <p className='block__text block__text--mod'><b>Safety Coachings:</b>: Conducted through the Motive driver app, these coaching’s allow drivers to learn from unsafe driving behaviors they encountered over the road. They incorporate real case scenarios and interactive elements, enabling drivers to share their experiences and safety challenges. This approach fosters open communication and helps drivers acknowledge, learn from, and understand real-world situations, making the coaching’s more engaging and educational.</p>
            <p className='block__text block__text--mod'><b>Monthly Safety Trainings</b>: Partnering with Impact e-learning solutions for monthly safety training demonstrates a commitment to ongoing driver education. To enhance the effectiveness of these trainings, we incorporate interactive simulations or scenario-based learning to help drivers apply safety principles in real-world situations.</p>
            <p className='block__text block__text--mod'><b>VEDR Telematics</b>: Implementing Motive AI dash cams for VEDR telematics significantly improves safety records by providing real-time monitoring, alerts, and objective evidence in the event of accidents or incidents. The biggest benefit of AI dash cams lies in their ability to:</p>
            <p className='block__text block__text--mod'>- <b>Enhance safety:</b> Provide real-time monitoring and feedback, alerting drivers to potential hazards and promoting safer driving habits.</p>
            <img loading='lazy' src='../images/AI2.png' alt='highway' className='block__img block__img--mod'/>
            <p className='block__text block__text--mod'>- <b>Prevent accidents:</b> Help reduce accidents by detecting unsafe driving behaviors and minimizing distractions.</p>
            <img loading='lazy' src='../images/AI.jpg' alt='highway' className='block__img block__img--mod'/>
            <p className='block__text block__text--mod'>- <b>Help drivers improve:</b> Recorded footage from AI dash cams can be used for driver training, allowing drivers to learn from their mistakes and improve their skills.</p>
            <img loading='lazy' src='../images/AI1.png' alt='highway' className='block__img block__img--mod'/>
            <p className='block__text block__text--mod'>In addition, Motive Safety Scores and accurate collision detection don’t just highlight safety trends; they allow us to incentivize drivers based on their behavior or improvement. The driver-focused element of Motive’s platform is unmatched, ensuring a proactive approach to safety. We ensure that drivers are properly trained on the use of these cameras and that privacy concerns are addressed transparently.</p>
            <p className='block__text block__text--mod'><b>Hours of Service Compliance</b>: Providing drivers with an easy-to-use ELD solution like the Intelligent Vehicle Gateway (IVG) ELD by Omnitracs is essential for ensuring compliance with Hours of Service regulations. We regularly update drivers on any changes to regulations and provide support to address any issues they may encounter with the ELD system.</p>
          </div>
          <div className='safety__sub'>
          <nav className='safety__nav'>
                <ul className='safety__list'>
                    <li className='safety__item'><Cards /></li>
                </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Safety
